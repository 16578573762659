import React from "react";
import "./MyWork.css";
// import theme_pattern from "../../assets/theme_pattern.svg";
import mywork_data from "../../assets/mywork_data";
import arrow_icon from "../../assets/arrow_icon.svg";

const MyWork = () => {
  return (
    <div id="work" className="mywork">
      <div className="mywork-title">
        <h1>My Latest Work</h1>
        {/* <img src={theme_pattern} alt=""></img> */}
      </div>
      <div className="mywork-desc">
        <p>
          Explore my latest photography projects, where each image hopefully
          tells a unique story. This collection features recent captures across
          various themes, including stunning landscapes, dynamic events, and
          thrilling action shots. Every photograph reflects my ongoing journey
          as a photographer, highlighting my passion for capturing moments in
          time. I hope you enjoy viewing these new works as much as I enjoyed
          creating them.
        </p>
      </div>
      <div className="mywork-container">
        {mywork_data.map((work, index) => {
          return <img key={index} src={work.w_img} alt="" />;
        })}
      </div>
      <div className="mywork-showmore">
        <a href="#gallery-anchor">Show More</a>
        <img src={arrow_icon} alt="" />
      </div>
    </div>
  );
};

export default MyWork;
