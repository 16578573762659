const eventphotos_data = [
  {
    w_no: 13,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/GYObLT5.jpeg",
  },
  {
    w_no: 14,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/vZQzvDK.jpeg",
  },
  {
    w_no: 15,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/qYVGxzF.jpeg",
  },
  {
    w_no: 16,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/AajNoJH.jpeg",
  },
  {
    w_no: 17,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/ZW6KMDS.jpeg",
  },
  {
    w_no: 18,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/6nTYKFo.jpeg",
  },
];

export default eventphotos_data;
