const actionphotos_data = [
  {
    w_no: 1,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/HKY5A8Z.jpeg",
  },
  {
    w_no: 2,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/EddlYM8.jpeg",
  },
  {
    w_no: 3,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/9E16tgT.jpeg",
  },
  {
    w_no: 4,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/EJtxezB.jpeg",
  },
  {
    w_no: 5,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/6MSRgIg.jpeg",
  },
  {
    w_no: 6,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/iwdeCx7.jpeg",
  },
];

export default actionphotos_data;
