const Services_Data = [
  {
    s_no: "01",
    s_name: "Event Photography",
    s_desc:
      "I offer professional event photography that captures the true energy and atmosphere of your occasion.",
    target: "#event-photo",
  },
  {
    s_no: "02",
    s_name: "Landscape & Fine Art",
    s_desc:
      "Specialising in landscape photography, I focus on capturing the raw beauty and mood of natural settings.",
    target: "#landscape-photo",
  },
  {
    s_no: "03",
    s_name: "Action Photography",
    s_desc:
      "My action photography is all about capturing fast-paced moments with precision and creativity.",
    target: "#action-photo",
  },
  //   {
  //     s_no: "04",
  //     s_name: "App design",
  //     s_desc: "Web development is the process of building, programming...",
  //   },
  //   {
  //     s_no: "05",
  //     s_name: "Digital marketing",
  //     s_desc: "Web development is the process of building, programming...",
  //   },
  //   {
  //     s_no: "06",
  //     s_name: "Content writing",
  //     s_desc: "Web development is the process of building, programming...",
  //   },
];

export default Services_Data;
