import React from "react";
import "./GalleryPhotos.css";
import actionPhotos_data from "../../assets/actionPhotos_data";
import landscapePhotos_data from "../../assets/landscapePhotos_data";
import eventPhotos_data from "../../assets/eventPhotos_data";

const GalleryPhotos = () => {
  return (
    <div id="photo" className="gallery-photos">
      <div className="gallery-title">
        <h1 id="gallery-anchor">Gallery</h1>
      </div>
      <div className="gallery-desc">
        <p>
          Welcome to my gallery. Here you will find a collection of my
          photography that reflects my work across various themes, including
          landscapes, events, and action. Each image showcases my approach to
          capturing moments and the beauty of the world around us. I invite you
          to take a moment to explore and enjoy…
        </p>
      </div>
      <h2 id="event-photo">Event Photos</h2>
      <div className="gallery-container">
        {eventPhotos_data.map((photo, index) => {
          return <img key={index} src={photo.w_img} alt="" />;
        })}
      </div>
      <h2 id="landscape-photo">Landscape Photos</h2>
      <div className="gallery-container">
        {landscapePhotos_data.map((photo, index) => {
          return <img key={index} src={photo.w_img} alt="" />;
        })}
      </div>
      <h2 id="action-photo">Action Photos</h2>
      <div className="gallery-container">
        {actionPhotos_data.map((photo, index) => {
          return <img key={index} src={photo.w_img} alt="" />;
        })}
      </div>
    </div>
  );
};

export default GalleryPhotos;
