const landscapephotos_data = [
  {
    w_no: 7,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/r0R0far.jpeg",
  },
  {
    w_no: 8,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/XjvmtOi.jpeg",
  },
  {
    w_no: 9,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/XED1cKW.jpeg",
  },
  {
    w_no: 10,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/9wi9g6Q.jpeg",
  },
  {
    w_no: 11,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/e0i2t35.jpeg",
  },
  {
    w_no: 12,
    w_name: "Gallery",
    w_img: "https://i.imgur.com/BnR4XzH.jpeg",
  },
];

export default landscapephotos_data;
