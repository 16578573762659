import React from "react";
import "./Pricing.css";

const Pricing = ({ onClose }) => {
  return (
    <div className="pricing-modal">
      <div className="pricing-modal-content">
        <h2 className="pricing-title">Pricing Information</h2>
        <p className="pricing-para">
          At The Solitary Photographer, we offer a range of photography packages
          tailored to suit various needs. Below is a detailed breakdown of our
          pricing structure, including package details, additional costs, and
          payment terms. However, life is rarely straightforward and these
          prices are for guidance only. Each arrangement will be unique and can
          be tailored to your specific requirements. Please get in touch to
          discuss what it is you are looking to achieve and we will go from
          there.
        </p>
        <h2 className="pricing-sub-head">Typical Photography Packages</h2>
        <div className="pricing-info-container">
          <h3>1. Landscape Photography Package</h3>
          <p>
            Price: £200 for a half-day session (up to 4 hours). Includes: Up to
            10 edited high-resolution images, online gallery for viewing and
            sharing, and a consultation to discuss your vision.
          </p>
          <h3>2. Event Photography Package</h3>
          <p>
            Price: £400 for a full-day event (up to 8 hours). Includes: Coverage
            of your event, unlimited candid and posed shots, 30 edited high-
            resolution images, and an online gallery. Additional hours can be
            added at £50 per hour.
          </p>
          <h3>3. Action Photography Package</h3>
          <p>
            Price: £250 for a half-day session (up to 4 hours). Includes:
            Coverage of sporting events or activities, up to 15 edited
            high-resolution images, and an online gallery for viewing and
            sharing.
          </p>
          <h3>4. Photography One 2 One Tuition</h3>
          <p>
            Price: £125 for a 2-hour tutorial Includes: 2 hours of personalised
            tuition tailored specifically to your needs. This will have been
            discussed and agreed beforehand. The venue can be of your own choice
            or suggested by myself, depending on what is required.
          </p>
          <h2>Additional Costs</h2>
          <p>
            Travel Fees: A travel fee may apply for locations outside of
            Northern Cumbria. The fee is £0.45 per mile for distances beyond
            this range. This will be discussed and agreed prior to payment.
            Prints and Products: Physical prints and products can be ordered at
            an additional cost. Pricing varies based on size and type—please
            inquire for specific rates and options. Extra Editing: Additional
            editing requests beyond the package inclusions will incur a fee of
            £15 per image.
          </p>
          <h2>Payment Terms and Methods</h2>
          <p>
            Deposit: A non-refundable deposit of 25% is required to secure your
            booking. The remaining balance is due one week before the scheduled
            date. Payment Methods: We accept payments via bank transfer, PayPal,
            and cash. Detailed payment instructions will be provided upon
            booking confirmation. Invoices: A formal invoice will be issued for
            all bookings, outlining the services provided and total costs. For a
            custom quote or to discuss your specific photography needs, please
            get in touch. We look forward to capturing your moments!
          </p>
        </div>
        <button onClick={onClose} className="pricing-close-btn">
          Close
        </button>
      </div>
    </div>
  );
};

export default Pricing;
