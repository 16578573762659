import React from "react";
import "./Hero.css";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Hero = () => {
  return (
    <div id="home" className="hero">
      <img src="https://i.imgur.com/oqqZG00.jpeg" alt="" />
      <h1>
        <span>I'm James,</span> The Solitary Photographer based in Cumbria
      </h1>
      <p>
        I’m James Critchley, a freelance photographer living in Cumbria, where
        the breath-taking scenery inspires much of my work.
      </p>
      <div className="hero-action">
        <div className="hero-connect">
          <AnchorLink className="anchor-link" offset={50} href="#contact">
            Connect with me
          </AnchorLink>
        </div>
        <div className="hero-resume">
          <AnchorLink className="anchor-link" offset={50} href="#photo">
            Gallery
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;
