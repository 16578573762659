import React from "react";
import "./About.css";

const About = () => {
  return (
    <div id="about" className="about">
      <div className="about-title">
        <h1>About me</h1>
      </div>
      <div className="about-sections">
        <div className="about-left">
          <img src="https://i.imgur.com/A2g5Jrg.jpeg" alt="Profile" />
        </div>
        <div className="about-right">
          <div className="about-para">
            <p>
              Specialising in landscape, event, and action photography, I strive
              to capture the essence of each moment.
            </p>
            <p>
              Whether it's the tranquillity of nature or the energy of a live
              event. With a keen eye for detail and a passion for storytelling,
              my goal is to deliver compelling images that resonate with
              viewers.
            </p>
          </div>
        </div>
      </div>
      <div className="about-achievements">
        <div className="about-achievement">
          <h1>30+</h1>
          <p>YEARS OF EXPERIENCE</p>
        </div>
        <hr className="lines" />
        <div className="about-achievement">
          <h1>100+</h1>
          <p>GIGS</p>
        </div>
        <hr className="lines" />
        <div className="about-achievement">
          <h1>100's</h1>
          <p>OF SATISFIED CUSTOMERS</p>
        </div>
      </div>
    </div>
  );
};

export default About;
