import React from "react";
import "./Services.css";
import Services_Data from "../../assets/services_data";
import arrow_icon from "../../assets/arrow_icon.svg";
import Pricing from "../Modal/Pricing"; // Adjust the import path as necessarys

const Services = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = React.useState(false);

  const openPricingModal = () => {
    setIsPricingModalOpen(true);
  };

  const closePricingModal = () => {
    setIsPricingModalOpen(false);
  };

  return (
    <div id="services" className="services">
      <div className="services-title">
        <h1>My Services</h1>
      </div>
      <div className="services-desc">
        <p>
          Welcome to my services section, where you can explore the diverse
          photography solutions I provide. Whether you’re looking for stunning
          landscape captures, engaging event photography, or energetic action
          shots, my goal is to provide high-quality images that resonate with
          you. Explore the various services available, and let’s work together
          to create something special!
        </p>
      </div>
      <div className="services-container">
        {Services_Data.map((service, index) => {
          return (
            <div key={index} className="services-format">
              <h3>{service.s_no}</h3>
              <h2>{service.s_name}</h2>
              <p>{service.s_desc}</p>
              <div className="services-readmore">
                <p>
                  <a href={service.target}>See More</a>
                </p>
                <img src={arrow_icon} alt="" />
              </div>
            </div>
          );
        })}
      </div>
      <div className="pricing-button-para">
        <p>
          For information about current pricing structures hit the pricing
          button below...
        </p>
      </div>
      <div className="services-pricing-btn-container">
        <button className="services-pricing-btn" onClick={openPricingModal}>
          See Pricing
        </button>
      </div>

      {isPricingModalOpen && <Pricing onClose={closePricingModal} />}
    </div>
  );
};

export default Services;

// import React from "react";
// import "./Services.css";
// import Services_Data from "../../assets/services_data";
// import arrow_icon from "../../assets/arrow_icon.svg";
// import Pricing from "../Pricing/Modal";

// const Services = () => {
//   const [isPricingModalOpen, setIsPricingModalOpen] = React.useState(false);

//   const openPricingModal = () => {
//     setIsPricingModalOpen(true);
//   };

//   const closePricingModal = () => {
//     setIsPricingModalOpen(false);
//   };

//   return (
//     <div id="services" className="services">
//       <div className="services-title">
//         <h1>My Services</h1>
//       </div>
//       <div className="services-desc">
//         <p>
//           Welcome to my services section, where you can explore the diverse
//           photography solutions I provide. Whether you’re looking for stunning
//           landscape captures, engaging event photography, or energetic action
//           shots, my goal is to provide high-quality images that resonate with
//           you. Explore the various services available, and let’s work together
//           to create something special!
//         </p>
//       </div>
//       <div className="services-container">
//         {Services_Data.map((service, index) => {
//           return (
//             <div key={index} className="services-format">
//               <h3>{service.s_no}</h3>
//               <h2>{service.s_name}</h2>
//               <p>{service.s_desc}</p>
//               <div className="services-readmore">
//                 <p>
//                   <a href={service.target}>See More</a>
//                 </p>
//                 <img src={arrow_icon} alt=""></img>
//               </div>
//             </div>
//           );
//         })}
//       </div>

//       <div className="services-pricing-btn-container">
//         <button className="services-pricing-btn" onClick={openPricingModal}>
//           See Pricing
//         </button>
//       </div>
//       {isPricingModalOpen && (
//         <Modal type="pricing" onClose={closePricingModal} />
//       )}
//     </div>
//   );
// };

// export default Services;
