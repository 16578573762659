const mywork_data = [
  {
    w_no: 1,
    w_name: "Web design",
    w_img: "https://i.imgur.com/4RQF6Yg.jpeg",
  },
  {
    w_no: 2,
    w_name: "Web design",
    w_img: "https://i.imgur.com/BRvx3Od.jpeg",
  },
  {
    w_no: 3,
    w_name: "Web design",
    w_img: "https://i.imgur.com/rOmGYNy.jpeg",
  },
  {
    w_no: 4,
    w_name: "Web design",
    w_img: "https://i.imgur.com/rSlau7N.jpeg",
  },
  {
    w_no: 5,
    w_name: "Web design",
    w_img: "https://i.imgur.com/erG3E9l.jpeg",
  },
  {
    w_no: 6,
    w_name: "Web design",
    w_img: "https://i.imgur.com/YDJnHTW.jpeg",
  },
  {
    w_no: 7,
    w_name: "Web design",
    w_img: "https://i.imgur.com/3OcT2Pc.jpeg",
  },
  {
    w_no: 8,
    w_name: "Web design",
    w_img: "https://i.imgur.com/t9L77Qf.jpeg",
  },
  {
    w_no: 9,
    w_name: "Web design",
    w_img: "https://i.imgur.com/HuQ0Uqj.jpeg",
  },
];

export default mywork_data;

// const mywork_data = [
//     {
//         w_no:1,
//         w_name:"Web design",
//         w_img:project1_img
//     },
//     {
//         w_no:2,
//         w_name:"Web design",
//         w_img:project2_img
//     },
//     {
//         w_no:3,
//         w_name:"Web design",
//         w_img:project3_img
//     },
//     {
//         w_no:4,
//         w_name:"Web design",
//         w_img:project4_img
//     },
//     {
//         w_no:5,
//         w_name:"Web design",
//         w_img:project5_img
//     },
//     {
//         w_no:6,
//         w_name:"Web design",
//         w_img:project6_img
//     },
// ]

// export default mywork_data;
