import React from "react";
import "./Modal.css";

const Modal = ({ type, onClose }) => {
  const getModalContent = () => {
    switch (type) {
      case "terms":
        return (
          <div className="terms-info">
            <h2>Terms of Service for The Solitary Photographer</h2>
            <p>
              Effective Date: September 2024 Welcome to The Solitary
              Photographer. By accessing our website and using our services, you
              agree to comply with and be bound by the following terms and
              conditions. Please read them carefully.
            </p>
            <h3>1. Acceptance of Terms</h3>
            <p>
              By using this website and our services, you confirm that you
              accept these terms and conditions. If you do not agree with any
              part of these terms, please refrain from using our services.
            </p>
            <h3>2. Services Offered</h3>
            <p>
              The Solitary Photographer provides photography services, including
              but not limited to landscape, event, and action photography.
              Detailed descriptions of our services can be found on our website.
            </p>
            <h2>3. Booking and Payment</h2>
            <p>
              To book our services, you may be required to provide personal
              information and pay a deposit. Full payment is due upon completion
              of the services unless otherwise agreed in writing. Prices are
              subject to change, but you will be informed of any changes before
              booking.
            </p>
            <h3>4. Cancellation and Rescheduling</h3>
            <p>
              Cancellations must be made in writing at least one week before the
              scheduled service to receive a refund of any deposit paid.
              Rescheduling is subject to availability and must be requested in
              writing.
            </p>
            <h3>5. Copyright and Use of Images</h3>
            <p>
              All images produced by The Solitary Photographer are copyrighted.
              You may use the images for personal use unless otherwise specified
              in writing. Commercial use of images requires prior written
              permission and may incur additional fees.
            </p>
            <h3>6. Limitation of Liability</h3>
            <p>
              The Solitary Photographer will not be liable for any indirect,
              incidental, or consequential damages arising from the use of our
              services or the inability to use our services.
            </p>
            <h3>7. Governing Law</h3>
            <p>
              These terms are governed by and construed in accordance with the
              laws of the United Kingdom. Any disputes arising from these terms
              will be subject to the exclusive jurisdiction of the courts of the
              UK.
            </p>
            <h3>8. Changes to Terms of Service</h3>
            <p>
              We reserve the right to update or modify these terms at any time.
              Changes will be effective immediately upon posting on this
              website. Your continued use of our services after any changes
              constitutes acceptance of the new terms.
            </p>
            <h3>9. Contact Us</h3>
            <p>
              If you have any questions about this privacy policy or our data
              practices regarding physical services, please contact us at: The
              Solitary Photographer Email: info@the-solitary-photographer.co.uk
              Phone: 07766333040 Website: www.the-solitary-photographer.co.uk
            </p>
            <button onClick={onClose}>Close</button>
          </div>
        );
      case "privacy":
        return (
          <div className="privacy-info">
            <h2>Website Privacy Policy</h2>
            <p>
              At The Solitary Photographer, we are committed to protecting your
              privacy when you visit our website. This policy outlines how we
              collect, use, and protect your personal information in compliance
              with the UK General Data Protection Regulation (UK GDPR).
            </p>
            <h3>1. Information We Collect</h3>
            <p>
              Effective Date: September 2024. When you visit our website, we may
              collect the following information: Personal Information: This
              includes details you provide directly, such as your name, email
              address, and contact information when you fill out forms or
              subscribe to our newsletter. Technical Data: This includes your IP
              address, browser type, pages visited, and the time and date of
              your visit. This information helps us improve our website and user
              experience.
            </p>
            <h3>2. Legal Basis for Processing Personal Data</h3>
            <p>
              We process your personal information under the following legal
              bases: Consent: We rely on your consent when you provide your
              information voluntarily. Legitimate Interests: We may process your
              data to improve our website and services.
            </p>
            <h3>3. How We Use Your Information</h3>
            <p>
              Your information may be used for: Responding to your inquiries and
              requests. Sending you updates and marketing communications (if you
              have opted in). Analysing website usage to improve our services
              and user experience.
            </p>
            <h3>4. Data Protection</h3>
            <p>
              We implement appropriate security measures to protect your
              personal information from unauthorised access, use, or disclosure.
              However, please be aware that no method of transmission over the
              internet is completely secure.
            </p>
            <h3>5. Sharing Your Information</h3>
            <p>
              We do not sell or rent your personal information to third parties.
              We may share your information with trusted third-party service
              providers who assist us in operating our website, and they are
              required to keep your information confidential.
            </p>
            <h3>6. Cookies</h3>
            <p>
              Our website uses cookies to enhance your experience. You can
              manage cookie preferences through your browser settings. However,
              declining cookies may limit your ability to access certain
              features of our website.
            </p>
            <h3>7. Your Rights</h3>
            <p>
              Under UK GDPR, you have rights regarding your personal
              information, including: The right to access your personal data.
              The right to rectify inaccurate data. The right to erase your
              data. The right to restrict or object to processing. The right to
              data portability. To exercise these rights, please contact us
              using the information below.
            </p>
            <h3>8. Changes to This Privacy Policy</h3>
            <p>
              We may update this privacy policy from time to time. Any changes
              will be posted on this page, and the effective date will be
              revised. We encourage you to review this policy periodically for
              any updates.
            </p>
            <h3>9. Contact Us</h3>
            <p>
              If you have any questions about this privacy policy or our data
              practices, please contact us at: The Solitary Photographer Email:
              info@the-solitary-photographer.co.uk Phone: 07766333040 Website:
              www.the-solitary-photographer.co.uk
            </p>
            <hr></hr>
            <h2>
              Privacy Policy for Physical Services by The Solitary Photographer
            </h2>
            <p>
              At The Solitary Photographer, we are committed to protecting your
              privacy when you engage with our photography services. This
              privacy policy explains how we collect, use, and protect your
              personal information in connection with our physical services.
            </p>
            <h3>1. Information We Collect</h3>
            <p>
              Effective Date: September 2024. When you book our photography
              services, we may collect the following information: Personal
              Information: This includes your name, contact details (email and
              phone number), address, and any other information necessary for
              service delivery. Service Details: Information related to the
              specific photography service requested (e.g., event type,
              location, date, and any specific requirements).
            </p>
            <h3>2. Legal Basis for Processing Personal Data</h3>
            <p>
              We process your personal information under the following legal
              bases: Consent: Where you have provided explicit consent for us to
              use your information for specific purposes. Contractual Necessity:
              When processing is necessary for the performance of a contract
              with you.
            </p>
            <h3>3. How We Use Your Information</h3>
            <p>
              Your information may be used for: Managing and fulfilling your
              photography bookings. Communicating with you regarding your
              services and confirming details. Sending invoices and processing
              payments. Following up for feedback or to discuss additional
              services. Sending marketing communications (with your consent).
            </p>
            <h3>4. Data Protection</h3>
            <p>
              We implement appropriate technical and organisational measures to
              protect your personal information from unauthorised access, loss,
              or misuse. While we strive to protect your data, no method of
              transmission over the internet is completely secure.
            </p>
            <h3>5. Sharing Your Information</h3>
            <p>
              We do not sell or rent your personal information to third parties.
              We may share your information with trusted partners and service
              providers solely to assist in fulfilling your photography
              services, who are required to keep your information confidential.
            </p>
            <h3>6. Payment Information</h3>
            <p>
              Payments for our services are processed through secure payment
              platforms. We do not store your credit card or financial
              information.
            </p>
            <h3>7. Your Rights</h3>
            <p>
              Under UK GDPR, you have the following rights regarding your
              personal information: The right to access your personal data. The
              right to rectify inaccurate or incomplete data. The right to erase
              your data (right to be forgotten). The right to restrict or object
              to processing. The right to data portability. To exercise these
              rights, please contact us using the information provided below.
            </p>
            <h3>8. Changes to This Privacy Policy</h3>
            <p>
              We may update this privacy policy periodically. Changes will be
              effective immediately upon posting on this page. We encourage you
              to review this policy regularly for any updates.
            </p>
            <h3>9. Contact Us</h3>
            <p>
              If you have any questions about this privacy policy or our data
              practices regarding physical services, please contact us at: The
              Solitary Photographer Email: info@the-solitary-photographer.co.uk
              Phone: 07766333040 Website: www.the-solitary-photographer.co.uk
            </p>
            <button onClick={onClose}>Close</button>
          </div>
        );
      case "cookies":
        return (
          <div className="cookies-info">
            <h2>Cookies Policy for The Solitary Photographer</h2>
            <p>
              Effective Date: September 2024 At The Solitary Photographer, we
              use cookies to enhance your experience on our website. This policy
              explains what cookies are, how we use them, and your options
              regarding their use.
            </p>
            <h3>1. What Are Cookies?</h3>
            <p>
              Cookies are small text files that are placed on your device
              (computer, tablet, or mobile) when you visit a website. They help
              the website recognise your device and store certain information
              about your preferences or past actions.
            </p>
            <h3>2. How We Use Cookies</h3>
            <p>
              We use cookies for various purposes, including: Essential Cookies:
              These cookies are necessary for the website to function properly
              and cannot be disabled. They allow you to navigate the site and
              use its features. Performance Cookies: These cookies collect
              information about how visitors use our website, such as which
              pages are visited most often. This helps us improve the website’s
              performance and user experience. Functionality Cookies: These
              cookies allow the website to remember your preferences (such as
              your username or language) and provide enhanced, more personalised
              features. Marketing Cookies: These cookies may be used to deliver
              advertisements that are relevant to you and your interests. They
              may also limit the number of times you see an advertisement.
            </p>
            <h3>3. Third-Party Cookies</h3>
            <p>
              We may allow third-party service providers to place cookies on
              your device. These providers may include analytics services or
              advertising partners. The use of their cookies is subject to their
              own privacy policies, and we do not have control over their
              practices.
            </p>
            <h3>4. Your Choices Regarding Cookies</h3>
            <p>
              When you visit our website, you will be informed about our use of
              cookies and given the option to accept or decline non-essential
              cookies. You can manage your cookie preferences through your
              browser settings, where you can choose to block or delete cookies.
              Please note that if you choose to disable cookies, some features
              of our website may not function properly.
            </p>
            <h3>5. Changes to This Cookies Policy</h3>
            <p>
              We may update this cookies policy from time to time. Any changes
              will be effective immediately upon posting on this page. We
              encourage you to review this policy periodically for any updates.
            </p>
            <h3>6. Contact Us</h3>
            <p>
              If you have any questions about this cookies policy or our use of
              cookies, please contact us at: The Solitary Photographer Email:
              info@the-solitary-photographer.co.uk Phone: 07766333040 Website:
              www.the-solitary-photographer.co.uk
            </p>
            <button onClick={onClose}>Close</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">{getModalContent()}</div>
    </div>
  );
};

export default Modal;
